import React from "react";
import CryotherapyFaqs from "../CryotherapyFaq";

const FAQsHero = ({ questions, title }) => {
  const split = title.split(" ");
  const firstWord = split[0];

  return (
    <div className="w-[90%] mb-[80px] lg:mb-[128px] mx-auto lg:w-3/5 ">
      <p className="fontFamily2 font-bold text-[30px] lg:text-[42px] leading-[51px] text-black_typography mb-4 ">
        <span className="gradieant_text pl-0"> {firstWord} </span>
        {title.replace(firstWord, "")} Q&A
      </p>
      <CryotherapyFaqs questions={questions} dataType="" />
    </div>
  );
};

export default FAQsHero;
