import React from 'react';
import Button from "../Button";

const HeroFAQ = ({ toggleFunc }) => (
  <div className="background_gradieant_FAQs mb-[80px] lg:mb-[108px] mt-10 lg:mt-20 h-[356px] px-[5%] w-full flex  justify-center items-center ">
    <div className="w-fit text-center flex flex-col gap-6  items-center">
      <h1 className="text-white font-bold text-[42px] leading-[51px] fontFamily2">
        Got Questions?
      </h1>
      <p className="w-full lg:w-[560px] text-base text-gray_50 leading-7">
        Read our FAQs to learn more about what we do here at HighKey Balance.
        Please contact us if you have more questions we can help answer.
      </p>
      <Button
        onClick={() => toggleFunc("contact")}
        texts={"Contact Us"}
        customClass={
          "uppercase text-base font-semibold text-white bg-primary_green fontFamily2 tracking-[0.03em]  w-[174px] rounded-sm h-12 transition hover:bg-green_400 hover:text-purple"
        }
      />
    </div>
  </div>
);

export default HeroFAQ;
