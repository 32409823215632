import { graphql } from "gatsby";
import React from "react";
import BookingSection from "../components/BookingSection";
import FAQsHero from "../components/FAQsHero";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import HeroFAQs from "../components/HeroFAQs";

const META_DATA = {
  title: "Wellness & Recovery Studio | Washington DC | HighKey Balance",
  description: `Recover, relax and recharge at Washington D.C.'s premier all-in-one wellness and recovery studio, plus whole body cryotherapy center. Call or book today!`,
};

const FAQs = ({ data }) => {
  return (
    <Layout>
      <Seo {...META_DATA} />
      <HeroFAQs />
      {data.faqs.nodes.map(({ faqsTitle, questions }) => {
        return (
          <div className="w-full mx-auto" key={faqsTitle}>
            <FAQsHero questions={questions} title={faqsTitle} />
          </div>
        );
      })}
      <BookingSection />
    </Layout>
  );
};

export default FAQs;

export const query = graphql`
  query GetAllFAQs {
    faqs: allSanityFaqs {
      nodes {
        faqsTitle
        questions {
          _rawAnswer
          questionText
        }
      }
    }
  }
`;
